export const APP_LOADING_START = 'APP_LOADING_START';
export const APP_LOADING_SUCCESS = 'APP_LOADING_SUCCESS';
export const APP_LOADING_ERROR = 'APP_LOADING_ERROR';

export const APP_INIT_START = 'APP_INIT_START';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_ERROR = 'APP_INIT_ERROR';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';

export const CHECK_AUTH_START = 'CHECK_AUTH_START';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_ERROR = 'CHECK_AUTH_ERROR';

export const SHOW_AUTH_FORM = 'SHOW_AUTH_FORM';
export const CLOSE_AUTH_FORM = 'CLOSE_AUTH_FORM';

export const OAUTH_INFO_START = 'OAUTH_INFO_START';
export const OAUTH_INFO_SUCCESS = 'OAUTH_INFO_SUCCESS';
export const OAUTH_INFO_ERROR = 'OAUTH_INFO_ERROR';

export const OAUTH_SEND_TOKEN_START = 'OAUTH_SEND_TOKEN_START';
export const OAUTH_SEND_TOKEN_SUCCESS = 'OAUTH_SEND_TOKEN_SUCCESS';
export const OAUTH_SEND_TOKEN_ERROR = 'OAUTH_SEND_TOKEN_ERROR';

export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNIN_CLEAR_ERROR = 'SIGNIN_CLEAR_ERROR';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SIGNUP_CLEAR_ERROR = 'SIGNUP_CLEAR_ERROR';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_REDIRECT_FALSE = 'SET_REDIRECT_FALSE';

export const RECOVERY_PASSWORD_START = 'RECOVERY_PASSWORD_START';
export const RECOVERY_PASSWORD_SUCCESS = 'RECOVERY_PASSWORD_SUCCESS';
export const RECOVERY_PASSWORD_ERROR = 'RECOVERY_PASSWORD_ERROR';
export const RECOVERY_PASSWORD_CLEAR_ERROR = 'RECOVERY_PASSWORD_CLEAR_ERROR';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_CLEAR_ERROR = 'RESET_PASSWORD_CLEAR_ERROR';

export const RESET_CHANGE_PASSWORD_START = 'RESET_CHANGE_PASSWORD_START';
export const RESET_CHANGE_PASSWORD_SUCCESS = 'RESET_CHANGE_PASSWORD_SUCCESS';
export const RESET_CHANGE_PASSWORD_ERROR = 'RESET_CHANGE_PASSWORD_ERROR';
export const RESET_CHANGE_PASSWORD_CLEAR_ERROR = 'RESET_CHANGE_PASSWORD_CLEAR_ERROR';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_CLEAR_ERROR = 'CHANGE_PASSWORD_CLEAR_ERROR';

export const GET_USER_INFO_START = 'GET_USER_INFO_START';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR';
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';

export const SET_USER_INFO_START = 'SET_USER_INFO_START';
export const SET_USER_INFO_SUCCESS = 'SET_USER_INFO_SUCCESS';
export const SET_USER_INFO_ERROR = 'SET_USER_INFO_ERROR';
export const SET_USER_INFO_CLEAR = 'SET_USER_INFO_CLEAR';

export const GET_USER_IS_LOGGED_START = 'GET_USER_IS_LOGGED_START';
export const GET_USER_IS_LOGGED_SUCCESS = 'GET_USER_IS_LOGGED_SUCCESS';
export const GET_USER_IS_LOGGED_ERROR = 'GET_USER_IS_LOGGED_ERROR';

export const USER_ACTIVATION_START = 'USER_ACTIVATION_START';
export const USER_ACTIVATION_SUCCESS = 'USER_ACTIVATION_SUCCESS';
export const USER_ACTIVATION_ERROR = 'USER_ACTIVATION_ERROR';
export const USER_ACTIVATION_CLEAR_ERROR = 'USER_ACTIVATION_CLEAR_ERROR';

export const SET_LOGO = 'SET_LOGO';

export const GET_HIGHLIGHTS_PROMO_START = 'GET_HIGHLIGHTS_PROMO_START';
export const GET_HIGHLIGHTS_PROMO_SUCCESS = 'GET_HIGHLIGHTS_PROMO_SUCCESS';
export const GET_HIGHLIGHTS_PROMO_ERROR = 'GET_HIGHLIGHTS_PROMO_ERROR';

export const GET_ALL_PROMO_START = 'GET_ALL_PROMO_START';
export const GET_ALL_PROMO_SUCCESS = 'GET_ALL_PROMO_SUCCESS';
export const GET_ALL_PROMO_ERROR = 'GET_ALL_PROMO_ERROR';

export const GET_PROMO_VIEWER_START = 'GET_PROMO_VIEWER_START';
export const GET_PROMO_VIEWER_SUCCESS = 'GET_PROMO_VIEWER_SUCCESS';
export const GET_PROMO_VIEWER_ERROR = 'GET_PROMO_VIEWER_ERROR';
export const GET_PROMO_VIEWER_RESET = 'GET_PROMO_VIEWER_RESET';

export const GET_PROMO_TAG_VIEWER_START = 'GET_PROMO_TAG_VIEWER_START';
export const GET_PROMO_TAG_VIEWER_SUCCESS = 'GET_PROMO_TAG_VIEWER_SUCCESS';
export const GET_PROMO_TAG_VIEWER_ERROR = 'GET_PROMO_TAG_VIEWER_ERROR';

export const GET_PROMO_INFO_START = 'GET_PROMO_INFO_START';
export const GET_PROMO_INFO_LOADING = 'GET_PROMO_INFO_LOADING';
export const GET_PROMO_INFO_SUCCESS = 'GET_PROMO_INFO_SUCCESS';
export const GET_PROMO_INFO_ERROR = 'GET_PROMO_INFO_ERROR';

export const GET_PROMO_TAG_START = 'GET_PROMO_TAG_START';
export const GET_PROMO_TAG_SUCCESS = 'GET_PROMO_TAG_SUCCESS';
export const GET_PROMO_TAG_ERROR = 'GET_PROMO_TAG_ERROR';

export const SET_PROMO_404_ERROR = 'SET_PROMO_404_ERROR';
export const RESET_PROMO_404_ERROR = 'RESET_PROMO_404_ERROR';

export const SHOW_INITIAL_LOADER = 'SHOW_INITIAL_LOADER';
export const HIDE_INITIAL_LOADER = 'HIDE_INITIAL_LOADER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const GET_VIEWER_START = 'GET_VIEWER_START';
export const GET_VIEWER_SUCCESS = 'GET_VIEWER_SUCCESS';
export const GET_VIEWER_ERROR = 'GET_VIEWER_ERROR';
export const GET_VIEWER_RESET = 'GET_VIEWER_RESET';

export const GET_CMS_INTRO_START = 'GET_CMS_INTRO_START';
export const GET_CMS_INTRO_SUCCESS = 'GET_CMS_INTRO_SUCCESS';
export const GET_CMS_INTRO_ERROR = 'GET_CMS_INTRO_ERROR';

export const GET_CMS_FOOTER_START = 'GET_CMS_FOOTER_START';
export const GET_CMS_FOOTER_SUCCESS = 'GET_CMS_FOOTER_SUCCESS';
export const GET_CMS_FOOTER_ERROR = 'GET_CMS_FOOTER_ERROR';

export const GET_CMS_PREMI_START = 'GET_CMS_PREMI_START';
export const GET_CMS_PREMI_SUCCESS = 'GET_CMS_PREMI_SUCCESS';
export const GET_CMS_PREMI_ERROR = 'GET_CMS_PREMI_ERROR';

export const GET_CMS_AUTHENTICATION_SUCCESS = 'GET_CMS_AUTHENTICATION_SUCCESS';
export const GET_CMS_PROFILE_SUCCESS = 'GET_CMS_PROFILE_SUCCESS';
export const GET_CMS_CONTACT_SUCCESS = 'GET_CMS_CONTACT_SUCCESS';
export const GET_CMS_HOW_IT_WORKS_SUCCESS = 'GET_CMS_HOW_IT_WORKS_SUCCESS';
export const GET_CMS_HOME_SUCCESS = 'GET_CMS_HOME_SUCCESS';
export const GET_CMS_CATALOG_SUCCESS = 'GET_CMS_CATALOG_SUCCESS';
export const GET_CMS_GAME_SUCCESS = 'GET_CMS_GAME_SUCCESS';
export const GET_CMS_ADVANTAGES_SUCCESS = 'GET_CMS_ADVANTAGES_SUCCESS';
export const GET_CMS_MECCANICA_SUCCESS = 'GET_CMS_MECCANICA_SUCCESS';

export const SET_APP_LANG_START = 'SET_APP_LANG_START';
export const SET_APP_LANG_SUCCESS = 'SET_APP_LANG_SUCCESS';
export const SET_APP_LANG_ERROR = 'SET_APP_LANG_ERROR';

export const GET_GAMES_START = 'GET_GAMES_START';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAMES_ERROR = 'GET_GAMES_ERROR';

export const GET_GAME_START = 'GET_GAME_START';
export const GET_GAME_SUCCESS = 'GET_GAME_SUCCESS';
export const GET_GAME_ERROR = 'GET_GAME_ERROR';

export const SET_GAME_RETRY = 'SET_GAME_RETRY';

export const CHECK_GAME_PLAYED_START = 'CHECK_GAME_PLAYED_START';
export const CHECK_GAME_PLAYED_SUCCESS = 'CHECK_GAME_PLAYED_SUCCESS';
export const CHECK_GAME_PLAYED_ERROR = 'CHECK_GAME_PLAYED_ERROR';
export const CHECK_GAME_PLAYED_RESET = 'CHECK_GAME_PLAYED_RESET';

export const CHECK_ALL_GAMES_PLAYED_START = 'CHECK_ALL_GAMES_PLAYED_START';
export const CHECK_ALL_GAMES_PLAYED_SUCCESS = 'CHECK_ALL_GAMES_PLAYED_SUCCESS';
export const CHECK_ALL_GAMES_PLAYED_ERROR = 'CHECK_ALL_GAMES_PLAYED_ERROR';

export const SEND_GAME_RESULT_START = 'SEND_GAME_RESULT_START';
export const SEND_GAME_RESULT_SUCCESS = 'SEND_GAME_RESULT_SUCCESS';
export const SEND_GAME_RESULT_ERROR = 'SEND_GAME_RESULT_ERROR';

export const SAVE_GAME_RESULT_START = 'SAVE_GAME_RESULT_START';
export const SAVE_GAME_RESULT_SUCCESS = 'SAVE_GAME_RESULT_SUCCESS';
export const SAVE_GAME_RESULT_ERROR = 'SAVE_GAME_RESULT_ERROR';
export const SAVE_GAME_RESULT_CLEAN_ERROR = 'SAVE_GAME_RESULT_CLEAN_ERROR';

export const CHECK_FORM_COMPILED = 'CHECK_FORM_COMPILED';
export const CHECK_FORM_PRIZE_ACCEPTED = 'CHECK_FORM_PRIZE_ACCEPTED';

export const INIT_FORM_COMPILED = 'INIT_FORM_COMPILED';

export const GET_CODE_START = 'GET_CODE_START';
export const GET_CODE_SUCCESS = 'GET_CODE_SUCCESS';
export const GET_CODE_ERROR = 'GET_CODE_ERROR';
export const GET_CODE_CLEAN_ERROR = 'GET_CODE_CLEAN_ERROR';
export const CLEAN_CODE_DATA = 'CLEAN_CODE_DATA';

export const GET_CODE_AFTER_GAME_START = 'GET_CODE_AFTER_GAME_START';
export const GET_CODE_AFTER_GAME_ERROR = 'GET_CODE_AFTER_GAME_ERROR';
export const GET_CODE_AFTER_GAME_SUCCESS = 'GET_CODE_AFTER_GAME_SUCCESS';

export const GET_LINK_START = 'GET_LINK_START';
export const GET_LINK_SUCCESS = 'GET_LINK_SUCCESS';
export const GET_LINK_ERROR = 'GET_LINK_ERROR';

export const GET_IW_START = 'GET_IW_START';
export const GET_IW_SUCCESS = 'GET_IW_SUCCESS';
export const GET_IW_ERROR = 'GET_IW_ERROR';
export const GET_IW_CLEAN_ERROR = 'GET_IW_CLEAN_ERROR';
export const CLEAN_IW_DATA = 'CLEAN_IW_DATA';

export const GET_NOPRIZE_START = 'GET_NOPRIZE_START';
export const GET_NOPRIZE_SUCCESS = 'GET_NOPRIZE_SUCCESS';
export const GET_NOPRIZE_ERROR = 'GET_NOPRIZE_ERROR';
export const GET_NOPRIZE_CLEAN_ERROR = 'GET_NOPRIZE_CLEAN_ERROR';
export const CLEAN_NOPRIZE_DATA = 'CLEAN_NOPRIZE_DATA';

export const GET_PREASSIGNED_START = 'GET_PREASSIGNED_START';
export const GET_PREASSIGNED_SUCCESS = 'GET_PREASSIGNED_SUCCESS';
export const GET_PREASSIGNED_ERROR = 'GET_PREASSIGNED_ERROR';
export const GET_PREASSIGNED_CLEAN_ERROR = 'GET_PREASSIGNED_CLEAN_ERROR';
export const CLEAN_PREASSIGNED_DATA = 'CLEAN_PREASSIGNED_DATA';

export const SEND_ERROR_LOG_START = 'SEND_ERROR_LOG_START';
export const SEND_ERROR_LOG_SUCCESS = 'SEND_ERROR_LOG_SUCCESS';
export const SEND_ERROR_LOG_ERROR = 'SEND_ERROR_LOG_ERROR';

export const SET_500_ERROR = 'SET_500_ERROR_START';
export const SESSION_EXPIRED_ERROR = 'SESSION_EXPIRED_ERROR';
export const OTHER_LOGIN_START = 'OTHER_LOGIN_START';
export const OTHER_LOGIN_END = 'OTHER_LOGIN_END';
export const OTHER_LOGIN_SUCCESS = 'OTHER_LOGIN_SUCCESS';
export const SET_APP_ERROR = 'SET_APP_ERROR';

export const GO_TO_PANEL_START = 'GO_TO_PANEL_START';
export const GO_TO_PANEL_SUCCESS = 'GO_TO_PANEL_SUCCESS';
export const GO_TO_PANEL_ERROR = 'GO_TO_PANEL_ERROR';

export const BOOT_APP_START = 'BOOT_APP_START';
export const BOOT_APP_SUCCESS = 'BOOT_APP_SUCCESS';
export const BOOT_APP_ERROR = 'BOOT_APP_ERROR';

export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';

export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FILTER = 'GET_PRODUCTS_FILTER';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const SET_ID_CATALOG = 'SET_ID_CATALOG';

export const GET_PRODUCT_LOADING = 'GET_PRODUCT_LOADING';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';

export const TO_WISHLIST_SUCCESS = 'TO_WISHLIST_SUCCESS';
export const TO_CART_SUCCESS = 'TO_CART_SUCCESS';
export const TO_CART_ERROR = 'TO_CART_ERROR';
export const TO_CART_CLEAR_ERROR = 'TO_CART_CLEAR_ERROR';

export const ACTIVATE_USER_START = 'ACTIVATE_USER_START';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';

export const ORDER_PRODUCT_START = 'ORDER_PRODUCT_START';
export const ORDER_PRODUCT_SUCCESS = 'ORDER_PRODUCT_SUCCESS';
export const OPEN_ORDER_MODAL = 'OPEN_ORDER_MODAL';
export const CLOSE_ORDER_MODAL = 'CLOSE_ORDER_MODAL';
export const CLEAR_ORDER = 'CLEAR_ORDER';

export const GET_RATEPHOTO_START = 'GET_RATEPHOTO_START';
export const GET_RATEPHOTO_SUCCESS = 'GET_RATEPHOTO_SUCCESS';
export const GET_RATEPHOTO_ERROR = 'GET_RATEPHOTO_ERROR';
export const GET_RATEPHOTO_CLEAN_ERROR = 'GET_RATEPHOTO_CLEAN_ERROR';
export const CLEAN_RATEPHOTO_DATA = 'CLEAN_RATEPHOTO_DATA';

export const GET_FILTERS_CATEGORIES_SUCCESS = 'GET_FILTERS_CATEGORIES_SUCCESS';
export const GET_FILTERS_BRANDS_SUCCESS = 'GET_FILTERS_BRANDS_SUCCESS';

export const GET_ALL_NEWS_SUCCESS = 'GET_ALL_NEWS_SUCCESS';
export const GET_SINGLE_NEWS_SUCCESS = 'GET_SINGLE_NEWS_SUCCESS';
export const DELETE_SINGLE_NEWS = 'DELETE_SINGLE_NEWS';

export const REMEMBER_START = 'REMEMBER_START';
export const ORDER_COMPLETED = 'ORDER_COMPLETED';

export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const CHECKOUT_ERROR_CLEAR = 'CHECKOUT_ERROR_CLEAR';

export const APP_QUERY = 'APP_QUERY';

export const GET_CMS_SINGLE_AGREEMENT_SUCCESS = 'GET_CMS_SINGLE_AGREEMENT_SUCCESS';
