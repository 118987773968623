import axios from 'axios';
import { sessionExpiredError } from '../store/actions/error';
import { getAlias } from '../utils/utils';
import store from '../store/index';

const alias = getAlias();
const source = window.location.href.includes('?app=true') ? 'app' : 'web';

const axiosRefresh = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  transformRequest: [
    function (data, headers) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');

      headers['X-Custom-Header'] = JSON.stringify({
        alias: alias,
        source: source,
        lang: localStorage.getItem('i18nextLng'),
      });
    },
  ],
});

const customAxiosNm = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

customAxiosNm.interceptors.request.use(
  function (config) {
    config.headers.common = {
      'X-Custom-Header': JSON.stringify({
        alias: alias,
        source: source,
        lang: localStorage.getItem('i18nextLng') || 'it',
      }),
    };

    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

customAxiosNm.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const errorResponse = error.response;

    if (isTokenExpiredError(errorResponse)) {
      if (Number(localStorage.getItem('exp')) == 1) {
        return resetTokenAndReattemptRequest(error);
      } else {
        return store.dispatch(sessionExpiredError());
      }
    }
    return Promise.reject(error);
  },
);

function isTokenExpiredError(errorResponse) {
  if (errorResponse.status === 401) {
    return true;
  } else {
    return false;
  }
}

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;

    const retryOriginalRequest = new Promise(resolve => {
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axiosRefresh({
        method: 'get',
        url: 'auth/refresh',
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      });
      if (!response.data) {
        return Promise.reject(error);
      }
      const newToken = response.data.token;
      localStorage.setItem('token', newToken);
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    /* localStorage.removeItem('token');
    store.dispatch(setRedirectFalse());
    store.dispatch(authLogoutSuccess()); */
    if (err.response.status === 401) {
      store.dispatch(sessionExpiredError());
    }
    return Promise.reject(err);
  }
}

function onAccessTokenFetched(access_token) {
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default customAxiosNm;
