import React, { Suspense } from 'react';
import { Loader } from '../../components/Ui';
import SessionExpired from '../../components/SessionExpired';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AuthContainer = styled.div`
  // background-color: #eaf0f3;
`;

const AuthLayout = props => {
  const C = props.cmp;
  const lazy = props.lazy;
  const isExpired = props.isExpired;
  const otherLoginLoading = props.otherLoginLoading;
  const otherLoginSuccess = props.otherLoginSuccess;

  let Comp = null;
  if (lazy) {
    Comp = (
      <Suspense fallback={<Loader show={true} />}>
        <C {...props} />
      </Suspense>
    );
  } else {
    Comp = <C {...props} />;
  }

  const queryApp = useSelector(state => state.app.appQuery);

  return (
    <AuthContainer>
      {Comp}
      <SessionExpired
        isExpired={isExpired && !otherLoginLoading && !otherLoginSuccess}
        queryApp={queryApp}
      />
    </AuthContainer>
  );
};

const mapStateToProps = state => {
  return {
    logo: state.app.config.logo,
    logoinverted: state.app.config.logo_inverted,
    isExpired: state.error.sessionExpired,
    otherLoginLoading: state.error.otherLoginLoading,
    otherLoginSuccess: state.error.otherLoginSuccess,
  };
};

export default connect(mapStateToProps)(AuthLayout);
