import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Loader, Logo, Modal, Text, Wrapper } from '../../components/Ui';
import html2pdf from 'html2pdf.js';
import customAxiosNm from '../../config/axios-refresh-token';
import { getUserInfo } from '../../store/actions';
import { useHistory } from 'react-router';
import { getCmsSingleAgreement } from '../../store/actions/cms';
function IndividualAgreement() {
  const cms = useSelector(state => state.cms.single_agreement[0]?.contents);
  const single_agreement_loading = useSelector(state => state.cms.single_agreement_loading);
  const { logo } = useSelector(state => state.app.config || {});
  const individual_agreement_accepted = useSelector(
    state => state.user?.user?.userinfo?.individual_agreement_accepted,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const ref = useRef();
  useEffect(() => {
    dispatch(getCmsSingleAgreement());
  }, []);
  useEffect(() => {
    if (individual_agreement_accepted && !showModalConfirm) {
      history.push('/');
    }
  }, [individual_agreement_accepted, showModalConfirm]);

  const content = (
    <div ref={ref} className='page-break'>
      {cms?.map(({ i18l }) => {
        return <Text>{i18l.content}</Text>;
      })}
    </div>
  );

  const generatePdfHandler = () => {
    setLoading(true);
    if (!ref.current) {
      console.error('Static HTML content is empty.');
      return;
    }
    html2pdf()
      .from(ref.current)
      .set({
        margin: [10, 10, 5, 10],
        filename: 'pdf.pdf',
        html2canvas: { scale: 1.5 },
        pagebreak: { before: '.pagebreak' },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait', putOnlyUsedFonts: true },
      })
      .toPdf()
      .output('blob')
      .then(pdf => {
        const pdfFile = new File([pdf], 'my-generated-pdf.pdf', { type: 'application/pdf' });
        var formData = new FormData();
        formData.append('pdf', pdfFile);
        customAxiosNm.post('/auth/savepdf', formData).then(res => {
          setLoading(false);
          setShowModal(false);
          setShowModalConfirm(true);
          dispatch(getUserInfo());
        });
      });
  };
  return Boolean(single_agreement_loading) ? (
    <Loader show={individual_agreement_accepted} />
  ) : (
    <>
      <Container>
        <Logo logo={logo} marginLeft='0' />
        {Boolean(individual_agreement_accepted === undefined) ? (
          ''
        ) : (
          <Wrapper>
            {content}
            <Button onClick={() => setShowModal(true)} style={{ margin: 'auto' }} active>
              ACCETTO L'ACCORDO
            </Button>
          </Wrapper>
        )}
      </Container>
      <Modal show={showModal} close={() => setShowModal(false)}>
        <Text>Sei sicuro di voler accettare le condizioni e procedere?</Text>
        <Button
          onClick={generatePdfHandler}
          style={{ margin: '30px auto' }}
          active
          loading={loading}
          disabled={loading}
        >
          Conferma
        </Button>
      </Modal>
      <Modal
        show={showModalConfirm}
        close={() => {
          setShowModalConfirm(false);
          history.push('/');
        }}
      >
        <Text>Operazione andata a buon fine, ti abbiamo inviato un’email di conferma</Text>
      </Modal>
    </>
  );
}

const Container = styled.div`
  padding: 50px;
`;
export default IndividualAgreement;
