import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text } from '../Ui';
import CustomSlider from '../customSlider';
import { Link } from 'react-router-dom';

const Image = styled.picture`
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const Slide = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ clr }) => !clr && 'background-color: #00000026'};
  color: ${({ clr }) => (clr ? clr : 'white')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CustomLink = styled.a`
  color: ${({ clr }) => (clr ? clr : '#ffffff')};
  border: 1px solid ${({ clr }) => (clr ? clr : '#ffffff')};
  padding: 3px 10px;
  margin-top: 15px;
  border-radius: 5px;
`;

function ViewerSlider() {
  const { viewer } = useSelector(state => state.homeViewer);
  const { token } = useSelector(state => state.auth);
  const [slidVisibil, setSlidVisibil] = useState([]);

  useEffect(() => {
    token
      ? setSlidVisibil(() =>
          viewer.filter(item => item.visibility === 'all' || item.visibility === 'logged'),
        )
      : setSlidVisibil(() =>
          viewer.filter(item => item.visibility === 'all' || item.visibility === 'not_logged'),
        );
  }, [viewer, token]);

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'custom-slider',
    autoplay: true,
    infinite: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <CustomSlider settings={settings}>
      {slidVisibil?.map((item, i) => (
        <Slide key={i}>
          <Image>
            <source media='(max-width: 768px)' srcset={item.img_mobile} />
            <source media='(min-width: 769px)' srcset={item.img_desktop} />
            <img src={item.img_desktop} alt='' />
          </Image>
          {!Boolean(item.i18l.calltoaction) ? (
            <a href={item.i18l?.url}>
              <Overlay clr={item.text_color}>
                <Text type='title' capit bold color={item.text_color}>
                  {item.i18l.title}
                </Text>
                <Text type='text' capit color={item.text_color}>
                  {item.i18l.content}
                </Text>
                {item.i18l.calltoaction && (
                  <CustomLink clr={item.text_color} href={item.i18l?.url}>
                    {item.i18l.calltoaction}
                  </CustomLink>
                )}
              </Overlay>{' '}
            </a>
          ) : (
            <Oveslick-dotsrlay clr={item.text_color}>
              <Text type='title' capit bold color={item.text_color}>
                {item.i18l.title}
              </Text>
              <Text type='text' capit color={item.text_color}>
                {item.i18l.content}
              </Text>
              {item.i18l.calltoaction && (
                <CustomLink clr={item.text_color} href={item.i18l?.url}>
                  {item.i18l.calltoaction}
                </CustomLink>
              )}
            </Oveslick-dotsrlay>
          )}
        </Slide>
      ))}
    </CustomSlider>
  );
}

export default ViewerSlider;
