import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text, Flex, Col } from '../Ui';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { ReactComponent as Face } from '../../assets/images/facebook.svg';
import { ReactComponent as Insta } from '../../assets/images/instagram.svg';
import { ReactComponent as In } from '../../assets/images/linkedin.svg';
import { ReactComponent as Tik } from '../../assets/images/tiktok.svg';
import { ReactComponent as Twit } from '../../assets/images/twitter.svg';
import { ReactComponent as Yout } from '../../assets/images/youtube.svg';
import { ReactComponent as Gmail } from '../../assets/images/gmail.svg';
import { ReactComponent as Phon } from '../../assets/images/phon.svg';
import { ReactComponent as ToUp } from '../../assets/images/toUp.svg';
import { respondTo } from '../../theme/mixin';

const FooterContainer = styled.footer`
  margin-top: 30px;
  border-radius: ${({ theme }) => theme.border_radius_generale_grande}
    ${({ theme }) => theme.border_radius_generale_grande} 0 0;
  overflow: hidden;
  position: relative;
`;

const TopBar = styled.div`
  background-color: ${({ theme }) => theme.footer_bg};
  padding: 5px;
  .flex {
    & > div:only-child {
      width: 100%;
      justify-content: center;
      text-align: center;
      div {
        justify-content: center;
      }
    }
    & > div:nth-child(1):nth-last-child(2),
    & > div:nth-child(2):nth-last-child(1) {
      width: 100%;
      justify-content: space-between;
      ${respondTo.sm`
        width: 48%;textWhite
     `};
    }
    & > div:nth-child(2):nth-last-child(2) {
      div {
        justify-content: flex-start;
        margin-top: 5px;
        ${respondTo.sm`
         justify-content: center;
         margin-top: 0;
     `};
      }
    }
    & > div:nth-child(1):nth-last-child(3),
    & > div:nth-child(1):nth-last-child(3) ~ div {
      width: 100%;
      justify-content: space-between;
      ${respondTo.sm`
        width: 33%;
     `};
    }
    & > div:last-child:not(:first-child) {
      text-align: left;
      ${respondTo.sm`
        text-align: right;
     `};
    }
    ${respondTo.md`
      //padding: 30px 50px 20px 80px;
      padding: 10px;
  `}
  }
  ${respondTo.md`
  padding: 10px;
  `}
  .textWhite {
    a {
      color: inherit;
    }
  }
  img {
    max-width: ${props => props.theme.footer_logo_size}px;
  }
  .col3 {
    p > p {
      font-size: 18px !important;
    }
  }
`;

const CustomFlex = styled(Flex)`
  svg {
    margin-right: 10px;
    font-size: 40px;
    color: ${props => props.color};
    width: ${({ gmail }) => (gmail ? '35px' : '22px')};
    height: ${({ gmail }) => !gmail && '22px'};
    ${respondTo.md`
     height: initial;
    width: ${({ gmail }) => (gmail ? '55px' : '30px')};
  `}
  }
  p > p {
    font-size: 18px !important;
  }

  .textSize {
    a {
      font-size: 14px;
    }
  }
  ${respondTo.md`
     margin-bottom: 0;
    
     `}
`;

const BottomBar = styled.div`
  background-color: ${({ theme }) => theme.bg_footer_basso};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  svg {
    width: 20px;
    margin-right: 10px;
  }
  .btn {
    svg {
      width: 30px;
    }
  }
  img {
    width: 50%;
  }
  & > :last-child {
    margin-right: 0;
    text-align: right;
  }
  ${respondTo.sm`
    svg {
    width: 30px;
    margin-right: 20px; 
  }
    `};
`;
const Social = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  img {
    width: 40px;
  }
  svg {
    width: 40px;
    margin: 0;
  }
`;

const IconUp = styled(ToUp)`
  cursor: pointer;
  background-color: ${props => props.theme.bg_bottoni};
  color: ${props => props.theme.colore_testo_bottoni};
  padding: 3px;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  margin-left: auto;
  display: block;
  ${respondTo.sm`
   margin-left: 0;
    `};
`;

const Footer = props => {
  const footercontent = useSelector(state => state.cms.footer);

  const footerEmail = footercontent[0]?.contents.find(el => el.name === 'footer_email');
  const footerTel = footercontent[0]?.contents.find(el => el.name === 'footer_tel');
  const footerDetails1 = footercontent[0]?.contents.find(el => el.name === 'footer_details1');
  const footerDetails2 = footercontent[0]?.contents.find(el => el.name === 'footer_details2');
  const footerOrario = footercontent[0]?.contents.find(el => el.name === 'footer_orario');
  const { footerSettings } = useSelector(
    state => JSON.parse(state.app.config.settings.option) || {},
  );

  const socials = {
    facebook: <Face />,
    instagram: <Insta />,
    linkedin: <In />,
    tiktok: <Tik />,
    youtube: <Yout />,
    twitter: <Twit />,
  };
  return (
    <Wrapper>
      <FooterContainer>
        <TopBar>
          <Flex justify='space-between' align='center' className='flex'>
            {footerEmail?.i18l.content && footerEmail?.i18l.content !== '<p>&nbsp;</p>' && (
              <Col padding='0' className='col1'>
                <CustomFlex
                  wrap='nowrap'
                  align='center'
                  gmail
                  color={footerEmail?.text_color || '#fff'}
                >
                  <Gmail />
                  <Text
                    as='p'
                    size={18}
                    color={footerEmail?.text_color || '#fff'}
                    className='textWhite'
                  >
                    {footerEmail?.i18l.content}
                  </Text>
                </CustomFlex>
              </Col>
            )}
            {footerTel?.i18l.content && Boolean(footerTel?.i18l.content !== '<p>&nbsp;</p>') && (
              <Col padding='0' className='col2'>
                <CustomFlex wrap='nowrap' align='center' color={footerTel?.text_color || '#fff'}>
                  <Phon phon />{' '}
                  <Text
                    as='p'
                    size={18}
                    color={footerTel?.text_color || '#fff'}
                    className='textWhite'
                  >
                    {footerTel?.i18l.content}
                  </Text>
                </CustomFlex>
              </Col>
            )}
            <Col className='col3'>
              <Text
                as='p'
                size={18}
                color={footerOrario?.text_color || '#fff'}
                className='textWhite'
              >
                {footerOrario?.i18l.content}
              </Text>
            </Col>
          </Flex>
          {(footerDetails1?.i18l.title ||
            footerDetails2?.i18l.title ||
            footerDetails1?.i18l.content ||
            footerDetails2?.i18l.content) && (
            <Flex justify='space-between' align='center'>
              {(footerDetails1?.i18l.content || footerDetails1?.i18l.title) && (
                <Col width={47}>
                  {footerDetails1?.i18l.title && (
                    <Text
                      as='h4'
                      type='smallLink'
                      bold
                      color={footerDetails1?.text_color || '#fff'}
                      className='textWhite'
                    >
                      {footerDetails1?.i18l.title}
                    </Text>
                  )}

                  {footerDetails1?.i18l.content && (
                    <Text color={footerDetails1?.text_color || '#fff'} className='textWhite'>
                      {footerDetails1?.i18l.content}
                    </Text>
                  )}
                </Col>
              )}

              {(footerDetails2?.i18l.content || footerDetails2?.i18l.title) && (
                <Col width={47}>
                  {footerDetails2?.i18l.title && (
                    <Text
                      as='h4'
                      type='smallLink'
                      bold
                      color={footerDetails2?.text_color || '#fff'}
                      className='textWhite'
                    >
                      {footerDetails2?.i18l.title}
                    </Text>
                  )}

                  {footerDetails2?.i18l.content && (
                    <Text color={footerDetails2?.text_color || '#fff'} className='textWhite'>
                      {footerDetails2?.i18l.content}
                    </Text>
                  )}
                </Col>
              )}
            </Flex>
          )}
        </TopBar>
        <BottomBar>
          <Social>
            {Object.keys(footerSettings)?.map(key => {
              const { visibility, link, icon_url, icon_visibility } = footerSettings[key];
              if (visibility === 'show' && link) {
                return (
                  <a
                    href={link}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ width: '40px', display: 'block', textAlign: 'center' }}
                  >
                    {icon_url && icon_visibility && icon_visibility === 'show' ? (
                      <img src={icon_url} alt='' />
                    ) : (
                      socials[key]
                    )}
                  </a>
                );
              }
              return null;
            })}
          </Social>
          <div className='btn'>
            <IconUp onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
          </div>
        </BottomBar>
      </FooterContainer>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
